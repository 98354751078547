import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

export default ({ data }) => {
  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Full Moon Waxing" keywords={[`Raleigh`, `Esthetician`]} />
      <Container className="text-center">
        <Row>
          <Col xs="12">
            <BackgroundImage
              Tag="div"
              className="full-moon-hero"
              fluid={data.swimmer.childImageSharp.fluid}
            >
              <Row>
                <Col lg="7">
                  <h1>Full Body Waxing and Skincare for Men and Women</h1>
                  <Link to="/schedule-appointment">
                    <button className="m-3">Schedule an Appointment</button>
                  </Link>
                </Col>
              </Row>
            </BackgroundImage>
          </Col>
        </Row>
        <Row className="p-3">
          <Col md="4" className="fm-promo-box my-3">
            <Img
              fluid={data.goldBikini.childImageSharp.fluid}
              alt="Schedule an Appointment with Full Moon Waxing"
            />
            <div className="fm-promo-headline-box">
              <div>
                <h3>Skincare for All</h3>
                <span className="mb-3 text-center">
                  Facials, Microdermabrasion, High Frequency Treatments and
                  More.
                </span>
                <Link to="/skincare">
                  <button>Skincare Services</button>
                </Link>
              </div>
            </div>
          </Col>
          <Col md="4" className="fm-promo-box my-3">
            <Img
              fluid={data.pullingBikini.childImageSharp.fluid}
              alt="Full Moon Waxing Services for Women"
            />
            <div className="fm-promo-headline-box">
              <div>
                <h3>Waxing for Women</h3>
                <span className="mb-3 text-center">
                  Throw away the razor and visit Full Moon Waxing for
                  perfectly-smooth skin that lasts.
                </span>
                <Link to="/waxing-services-for-women">
                  <button>Waxing for Women</button>
                </Link>
              </div>
            </div>
          </Col>
          <Col md="4" className="fm-promo-box my-3">
            <Img
              fluid={data.mansBack.childImageSharp.fluid}
              alt="Full Moon Waxing Services for Men"
            />
            <div className="fm-promo-headline-box">
              <div>
                <h3>Waxing for Men</h3>
                <span className="mb-3 text-center">
                  For men committed to looking their best, hair removal and
                  complete skincare solutions.
                </span>
                <Link to="/waxing-services-for-men">
                  <button>Waxing for Men</button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="full-moon-meet-specialist p-3">
          <Row>
            <Col className="">
              <h2 className="mx-3">Meet Your Skincare Specialist</h2>
            </Col>
          </Row>
          <Row className="p-3">
            <Col md="4">
              <Img
                fluid={data.sarahMace.childImageSharp.fluid}
                className=""
                alt="Sarah Mace owner of Full Moon Waxing"
              />
            </Col>
            <Col md="8" className="note-from-sarah">
              <div
                dangerouslySetInnerHTML={{ __html: data.meetSpecialist.html }}
              ></div>
            </Col>
          </Row>
        </Row>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query {
    swimmer: file(relativePath: { eq: "swimmer.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    goldBikini: file(relativePath: { eq: "gold-bikini-woman.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pullingBikini: file(relativePath: { eq: "pulling-bikini-bottom.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mansBack: file(relativePath: { eq: "man-back.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sarahMace: file(relativePath: { eq: "new-sarah.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    meetSpecialist: markdownRemark(
      frontmatter: { title: { eq: "Meet Your Skincare Specialist" } }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`
